var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItemDiv, MenuItemTypography, StyledMenuItem, StyledSelectTenantButton, StyledTenantSelected } from './SettingsMenu.styles';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Divider, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../common/hooks/authentication';
import { useLookupUserQuery } from '../../../api/auth0User';
import { useGetTenantsQuery } from '../../../api/profileApi';
import TenantImage from '../../molecules/TenantImage';
import AddIcon from '@mui/icons-material/Add';
import { getConfig } from '../../../common/config';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { EscalationDialogue } from '../../EscalationDialogue/EscalationDialogue';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export default function SettingsMenu() {
    var _this = this;
    var _a = useAuthentication(), isPlazaUser = _a.isPlazaUser, parentTenantId = _a.parentTenant, currentTenantId = _a.tenant, has = _a.has, role = _a.role, isPricer = _a.isPricer;
    var tenants = useGetTenantsQuery().data;
    var navigate = useNavigate();
    var _b = React.useState(false), edOpen = _b[0], setEdOpen = _b[1];
    var _c = useLookupUserQuery(), user = _c.data, isLoading = _c.isLoading;
    var nameOrNotFound = user ? user.name : 'Not Found';
    var name = isLoading ? 'Loading...' : nameOrNotFound;
    var parentTenant = (tenants === null || tenants === void 0 ? void 0 : tenants.find(function (tenant) { return tenant.id === parentTenantId; })) || undefined;
    var currentTenant = (tenants === null || tenants === void 0 ? void 0 : tenants.find(function (tenant) { return tenant.id === currentTenantId; })) || undefined;
    var tenantName = (currentTenant === null || currentTenant === void 0 ? void 0 : currentTenant.name) || 'Unknown';
    var _d = React.useState(null), anchorElUser = _d[0], setAnchorElUser = _d[1];
    var toggleMenu = function (event) {
        if (!anchorElUser) {
            setAnchorElUser(event.currentTarget);
        }
    };
    var handleCloseUserMenu = function () {
        setAnchorElUser(null);
    };
    function handleAuth0Escalation() {
        setEdOpen(true);
        setAnchorElUser(null);
    }
    var goBackToTenantSelector = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isPlazaUser()) return [3 /*break*/, 2];
                    return [4 /*yield*/, loginWithRedirect()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    handleCloseUserMenu();
                    navigate('/tenants/' + parentTenant.id);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var gotoProfile = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleCloseUserMenu();
            navigate('/profile');
            return [2 /*return*/];
        });
    }); };
    function onManageServices() {
        setAnchorElUser(null);
        navigate('/admin/services');
    }
    var _e = useAuth0(), logout = _e.logout, loginWithRedirect = _e.loginWithRedirect;
    var currentRoleName = capitalizeFirstLetter(role.replace('pricer-', ''));
    var smartSheetFeature = getConfig().enableSmartSheetCreateTenant || false;
    if (!parentTenant) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { children: [_jsx(EscalationDialogue, { title: "Permission setting", open: edOpen, onClose: function () { return setEdOpen(false); }, description: "Please change your permission setting to get a temporary permission key for 6 days" }), _jsxs(StyledSelectTenantButton, { onClick: toggleMenu, children: [_jsx(TenantImage, { image: parentTenant.image, showText: false, style: { objectFit: 'contain', borderStyle: 'none' } }), _jsxs(StyledTenantSelected, { children: [name, _jsx("br", {}), _jsxs("small", { "data-testid": "current-name-and-role", children: [tenantName, " ( ", currentRoleName, " )"] })] }), _jsx(ArrowDropDownIcon, { style: { color: '#054816' } }), _jsxs(Menu, { sx: { mt: '55px' }, anchorEl: anchorElUser, anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }, keepMounted: true, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }, open: Boolean(anchorElUser), onClose: handleCloseUserMenu, children: [isPricer && currentTenantId !== parentTenantId && (_jsx(StyledMenuItem, { onClick: goBackToTenantSelector, children: _jsxs("div", { children: [_jsxs(MenuItemDiv, { children: [_jsx(SwitchAccountIcon, { fontSize: 'small' }), _jsx(MenuItemTypography, { children: "Back to parent" })] }), _jsx("div", { children: _jsxs(MenuItemTypography, { fontSize: '10px', "data-testid": "current-tenant", children: ["Switch from ", tenantName, " to parent tenant"] }) })] }) })), isPricer && (_jsx(StyledMenuItem, { onClick: handleAuth0Escalation, children: _jsxs("div", { children: [_jsxs(MenuItemDiv, { children: [_jsx(LockOpenIcon, { fontSize: 'small' }), _jsx(MenuItemTypography, { children: "Change permission" })] }), _jsx("div", { style: { width: '100%' }, children: _jsx(MenuItemTypography, { fontSize: '10px', "data-testid": "current-role", children: currentRoleName }) })] }) })), has('tenant:create') && _jsx(Divider, {}), has('tenant:create') && smartSheetFeature && (_jsxs(MenuItem, { component: "a", href: "https://app.smartsheet.com/b/azurelogin?lgt=wf&lrt=s&EQBCT=f28e85af5d644ee5a200fce9fdd91600&sua=home", target: "_blank", children: [_jsx(AddIcon, { sx: { mr: 1 } }), _jsx(Typography, { "data-testid": "request-new-tenant-menu-option", fontWeight: 'bold', children: "Request plaza tenant" })] })), has('tenant:create') && (_jsxs(MenuItem, { onClick: function () {
                                    return navigate("/tenants/".concat(currentTenantId, "/create/onprem"));
                                }, children: [_jsx(AddIcon, { sx: { mr: 1 } }), _jsx(Typography, { "data-testid": "create-on-prem-tenant-menu-option", fontWeight: 'bold', children: "Create on-prem tenant" })] })), has('tenant:super-admin') && (_jsx(StyledMenuItem, { onClick: onManageServices, children: _jsxs(MenuItemDiv, { children: [_jsx(AppRegistrationIcon, { fontSize: 'small' }), _jsx(Typography, { fontWeight: 'bold', children: "Manage services" })] }) })), isPricer && _jsx(Divider, {}), _jsx(StyledMenuItem, { onClick: gotoProfile, children: _jsxs(MenuItemDiv, { children: [_jsx(ManageAccountsIcon, { fontSize: 'small' }), _jsx(Typography, { fontWeight: 'bold', children: "Profile" })] }) }), _jsx(Divider, {}), _jsx(StyledMenuItem, { onClick: function () {
                                    return logout({ logoutParams: { returnTo: window.location.origin } });
                                }, children: _jsxs(MenuItemDiv, { children: [_jsx(LogoutIcon, { fontSize: 'small' }), _jsx(Typography, { fontWeight: 'bold', children: "Logout" })] }) })] })] })] }));
}
