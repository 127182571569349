var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopMenu from './components/topMenu/TopMenu';
import ErrorHandler from './components/errorHandling/ErrorHandler';
import { useAuth0 } from '@auth0/auth0-react';
import TenantSelectorPage from './pages/tenantSelector/TenantSelectorPage';
import { useAuthentication } from './common/hooks/authentication';
import { TenantEditorPage } from './pages/TenantEditor/TenantEditorPage';
import { ImageEditorPage } from './pages/TenantEditor/ImageEditorPage';
import { DeleteTenantPage } from './pages/DeleteTenant/DeleteTenantPage';
import { TenantCreatorPage } from './pages/TenantCreator/TenantCreatorPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { LinkingPage } from './pages/TenantLinks/LinkingPage';
import { GlobalServicesManagerPage } from './pages/GlobalServicesManager/ServicesEditorPage';
import ProfilePage from './pages/Profile/ProfilePage';
import NoAccessPage from './pages/NoAccessPage/NoAcccessPage';
export default function Application() {
    var _this = this;
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading, error = _a.error;
    var _b = useAuthentication(), switchTenant = _b.switchTenant, fetchToken = _b.fetchToken, tenant = _b.tenant, hasToken = _b.hasToken, role = _b.role, isPricer = _b.isPricer;
    if (error) {
        console.error('Auth0 error: ', error);
    }
    useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(isAuthenticated && !isLoading)) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchToken()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })().catch(console.error);
    }, [isLoading, isAuthenticated, switchTenant, fetchToken, hasToken]);
    if (!isAuthenticated && !isLoading) {
        return _jsx(LoginPage, {});
    }
    if (!hasToken) {
        return _jsx("div", { children: "Authorizing..." });
    }
    if (!isPricer) {
        return _jsx(NoAccessPage, {});
    }
    var defaultLink = "/tenants/".concat(tenant);
    return (_jsxs("div", { children: [_jsx(TopMenu, {}), _jsx(ErrorHandler, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/tenants/:id", element: _jsx(TenantSelectorPage, {}) }), _jsx(Route, { path: "/tenants/:id/edit", element: _jsx(TenantEditorPage, {}) }), _jsx(Route, { path: "/tenants/:id/edit-image", element: _jsx(ImageEditorPage, {}) }), _jsx(Route, { path: "/tenants/:id/add-image", element: _jsx(ImageEditorPage, {}) }), _jsx(Route, { path: "/admin/services", element: _jsx(GlobalServicesManagerPage, {}) }), _jsx(Route, { path: "/tenants/:id/delete", element: _jsx(DeleteTenantPage, {}) }), _jsx(Route, { path: "/tenants/:id/create", element: _jsx(TenantCreatorPage, {}) }), _jsx(Route, { path: "/tenants/:id/create/onprem", element: _jsx(TenantCreatorPage, { isOnprem: true }) }), _jsx(Route, { path: "/tenants/:id/edit-links", element: _jsx(LinkingPage, {}) }), _jsx(Route, { path: "/profile", element: _jsx(ProfilePage, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: defaultLink }) })] }) })] }));
}
