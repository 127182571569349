var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useCreateTenantMutation } from '../../../api/tenantApi';
import CustomCollapse from '../../../components/atoms/CustomCollapse';
import { profileApi } from '../../../api/profileApi';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { FormTextField } from '../../../components/atoms/FormTextField';
import { UploadImageButton } from '../../../components/atoms/UploadImageButton';
import { FormSelect } from '../../../components/atoms/FormSelect';
import { CONTRACT_STATUS, DEFAULT_CONTRACT_STATUS, DEFAULT_LICENSE_TYPE, DEFAULT_ONPREM_CONTRACT_STATUS, DEFAULT_ONPREM_LICENSE_TYPE, DEFAULT_ONPREM_TYPE, DEFAULT_TYPE, LICENSE_TYPES, TYPES } from '../../../common/tenant.constans';
export default function CreateTenantView(_a) {
    var _this = this;
    var currentTenant = _a.tenant, _b = _a.isOnprem, isOnprem = _b === void 0 ? false : _b;
    var navigate = useNavigate();
    var createTenant = useCreateTenantMutation()[0];
    var dispatch = useDispatch();
    var _c = useState(''), name = _c[0], setName = _c[1];
    var _d = useState(''), description = _d[0], setDescription = _d[1];
    var _e = useState(isOnprem ? DEFAULT_ONPREM_TYPE : DEFAULT_TYPE), tenantType = _e[0], setTenantType = _e[1];
    var _f = useState(isOnprem ? DEFAULT_ONPREM_LICENSE_TYPE : DEFAULT_LICENSE_TYPE), licenseType = _f[0], setLicenseType = _f[1];
    var _g = React.useState(''), image = _g[0], setImage = _g[1];
    var _h = useState(isOnprem ? DEFAULT_ONPREM_CONTRACT_STATUS : DEFAULT_CONTRACT_STATUS), contractStatus = _h[0], setContractStatus = _h[1];
    var _j = React.useState(''), errorMessage = _j[0], setErrorMessage = _j[1];
    var handleClose = function () {
        setName('');
        setDescription('');
        setImage('');
        setTenantType(DEFAULT_TYPE);
        setContractStatus(DEFAULT_CONTRACT_STATUS);
        setLicenseType(DEFAULT_LICENSE_TYPE);
        navigate("/tenants/".concat(currentTenant.id));
    };
    var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!name || name === '') {
                        setErrorMessage('You must provide a name');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createTenant({
                            parentId: currentTenant.id,
                            name: name,
                            description: description,
                            type: tenantType,
                            image: image,
                            licenseType: licenseType,
                            contractStatus: contractStatus,
                            targetForDecommission: false,
                            links: [],
                            services: [],
                            crmSystems: []
                        }).unwrap()];
                case 2:
                    _a.sent();
                    dispatch(profileApi.util.invalidateTags(['Profiles']));
                    handleClose();
                    setImage('');
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error('Failed to save: ', e_1);
                    setErrorMessage('Failed to save tenant');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onNameChange = function (value) {
        if (value || value.length > 0) {
            setErrorMessage('');
        }
        setName(value);
    };
    return (_jsx(Box, { padding: 2, children: _jsxs(Stack, { maxWidth: "400px", spacing: 2, children: [_jsx(CustomCollapse, { errorMessage: errorMessage, onClose: function () { return setErrorMessage(''); } }), _jsx(Typography, { variant: "h4", children: isOnprem ? 'Create Onprem Tenant' : 'Create Tenant' }), _jsx(FormTextField, { id: "name", label: "Tenant Name", value: name, autoFocus: true, onChange: onNameChange }), _jsx(FormTextField, { id: "description", label: "Tenant Description", value: description, onChange: function (value) { return setDescription(value); } }), _jsx(UploadImageButton, { onSetImage: function (name, data) { return setImage(data); }, onRemoveImage: function () { return setImage(''); } }), _jsx(FormSelect, { id: "type", label: "Type", disabled: isOnprem, onChange: function (value) { return setTenantType(value); }, value: tenantType, options: TYPES }), _jsx(FormSelect, { id: "license", label: "License Type", disabled: isOnprem, onChange: function (value) { return setLicenseType(value); }, value: licenseType, options: LICENSE_TYPES }), _jsx(FormSelect, { id: "contract-status", label: "Contract Status", disabled: isOnprem, value: contractStatus, onChange: function (value) { return setContractStatus(value); }, options: CONTRACT_STATUS }), _jsxs(Box, { display: "flex", justifyContent: "space-between", padding: 2, children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { onClick: onSubmit, children: "Update" })] })] }) }));
}
