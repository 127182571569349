var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, Box, Button, Dialog, Divider, ListItemAvatar, ListItemButton, Paper, TextField } from '@mui/material';
import { StyledEscalationAvatar, StyledEscalationDialogDescription, StyledEscalationDialogTitle, StyledEscalationReasonArea, StyledEscalationSubHeader, StyledEscalationTagArea, StyledIconButton, StyledList } from './EscalationDialogue.styles';
import TagIcon from '@mui/icons-material/Tag';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import EscalationIcon from './atoms/EscalationIcon';
import ListItemTextRoleLabel, { getRoles } from './atoms/ListItemTextRoleLabel';
import { useAuthentication } from '../../common/hooks/authentication';
import { ReasonTextField } from '../atoms/ReasonTextField';
import { useGetTagsQuery } from '../../api/tagApi';
import { getConfig } from '../../common/config';
export function EscalationDialogue(_a) {
    var _this = this;
    var _b;
    var onClose = _a.onClose, open = _a.open, title = _a.title, description = _a.description;
    var _c = useAuthentication(), elevate = _c.elevate, currentRole = _c.role, currentTagRoleData = _c.tags;
    var _d = useState(''), tagSearch = _d[0], setTagSearch = _d[1];
    var _e = useState(undefined), selectedRole = _e[0], setSelectedRole = _e[1];
    var _f = useState(null), selectedTag = _f[0], setSelectedTag = _f[1];
    var _g = useState(null), selectedTagRole = _g[0], setSelectedTagRole = _g[1];
    var tags = useGetTagsQuery({
        search: tagSearch,
        size: 40
    }).data;
    var _h = useState(''), reason = _h[0], setReason = _h[1];
    var roles = getRoles();
    useEffect(function () {
        var _a;
        if (!selectedTag &&
            !selectedTagRole &&
            !selectedRole &&
            currentTagRoleData &&
            (tags === null || tags === void 0 ? void 0 : tags.data)) {
            var tag_1 = (_a = tags.data) === null || _a === void 0 ? void 0 : _a.find(function (tag) { return currentTagRoleData[tag.tag]; });
            if (tag_1) {
                setSelectedTag(getTagOption(tag_1));
                setSelectedTagRole(roles['professional-tag'].find(function (role) { return role.value === currentTagRoleData[tag_1.tag]; }));
            }
        }
    }, [
        currentTagRoleData,
        tags,
        selectedTag,
        selectedTagRole,
        selectedRole,
        roles
    ]);
    var doSubmit = function (_event) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onClose();
                    return [4 /*yield*/, elevate(selectedRole, selectedTagRole === null || selectedTagRole === void 0 ? void 0 : selectedTagRole.value, selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.value, reason)];
                case 1:
                    _a.sent();
                    setReason('');
                    setSelectedRole('');
                    setSelectedTagRole(null);
                    setSelectedTag(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var cancelEsc = function (_event) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            onClose();
            setReason('');
            setSelectedRole('');
            setSelectedTagRole(null);
            setSelectedTag(null);
            return [2 /*return*/];
        });
    }); };
    var reasonOnChange = function (value) {
        setReason(value);
    };
    var handleMenuItemClick = function (role) { return __awaiter(_this, void 0, void 0, function () {
        var element;
        return __generator(this, function (_a) {
            if (role === currentRole) {
                return [2 /*return*/];
            }
            else {
                setSelectedRole(role);
                setSelectedTagRole(null);
                setSelectedTag(null);
            }
            element = document.getElementById('reason-field');
            if (element) {
                element.focus();
            }
            return [2 /*return*/];
        });
    }); };
    var disableEscalationSubmit = function () {
        return !(reason !== '' &&
            (selectedRole !== '' || (selectedTagRole && selectedTag)));
    };
    var TagDropDownHelperText = function (props) {
        return (_jsxs(Paper, __assign({ elevation: 4 }, props, { children: [props.children, _jsx(Box, { sx: {
                        backgroundColor: '#f8f6f6',
                        fontSize: 12,
                        fontStyle: 'italic',
                        padding: '8px',
                        textAlign: 'center'
                    }, children: "Search for more specific tags" })] })));
    };
    var getTagOption = function (tag) {
        return { label: tag.displayName || tag.tag, value: tag.tag, id: tag.id };
    };
    var handleTagSearch = function (_event, value, reason) {
        if (reason === 'input') {
            setTagSearch(value);
        }
    };
    var handleTagSelection = function (_, value) {
        setSelectedRole('');
        setSelectedTag(value);
    };
    var handleTagRoleSelection = function (_, value) {
        setSelectedRole('');
        setSelectedTagRole(value);
    };
    return (_jsxs(Dialog, { onClose: cancelEsc, open: open, children: [_jsx(StyledIconButton, { "aria-label": "close", color: "inherit", size: "small", onClick: cancelEsc, children: _jsx(CloseIcon, { fontSize: "inherit" }) }), _jsx(StyledEscalationDialogTitle, { children: title }), _jsx(StyledEscalationDialogDescription, { children: description }), _jsx(StyledEscalationSubHeader, { children: "Pricer Roles" }), _jsx(StyledList, { children: roles['pricer'].map(function (role) { return (_jsxs(ListItemButton, { onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                        return [2 /*return*/, handleMenuItemClick(role.value)];
                    }); }); }, selected: role.value === selectedRole, children: [_jsx(ListItemAvatar, { children: _jsx(StyledEscalationAvatar, { children: _jsx(EscalationIcon, { role: role.value, selectedRole: selectedRole }) }) }), _jsx(ListItemTextRoleLabel, { role: role, currentRole: currentRole })] }, role.value)); }) }), !getConfig().disableProfessionalRoleEscalation && (_jsxs(_Fragment, { children: [_jsx(StyledEscalationSubHeader, { children: "Professional Roles" }), _jsx(StyledList, { children: roles['professional-tenant'].map(function (role) { return (_jsxs(ListItemButton, { onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/, handleMenuItemClick(role.value)];
                            }); }); }, selected: role.value === selectedRole, children: [_jsx(ListItemAvatar, { children: _jsx(StyledEscalationAvatar, { children: _jsx(EscalationIcon, { role: role.value, selectedRole: selectedRole }) }) }), _jsx(ListItemTextRoleLabel, { role: role, currentRole: currentRole })] }, role.value)); }) }), _jsxs(StyledEscalationTagArea, { children: [_jsx(ListItemAvatar, { children: _jsx(StyledEscalationAvatar, { children: _jsx(TagIcon, { sx: { color: '#054816' } }) }) }), _jsxs(Box, { sx: { minWidth: '200px' }, children: [_jsx(Autocomplete, { size: "small", fullWidth: true, disablePortal: true, id: "tag-role-input", options: roles['professional-tag'] || [], isOptionEqualToValue: function (option, value) {
                                            return option.value === value.value;
                                        }, onChange: handleTagRoleSelection, value: selectedTagRole, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: "Tag Role" }))); } }), _jsx(Autocomplete, { size: "small", fullWidth: true, disablePortal: true, sx: { marginTop: '10px' }, id: "tag-input", options: ((_b = tags === null || tags === void 0 ? void 0 : tags.data) === null || _b === void 0 ? void 0 : _b.map(function (tag) { return getTagOption(tag); })) || [], isOptionEqualToValue: function (option, value) { return option.id === value.id; }, onChange: handleTagSelection, onInputChange: handleTagSearch, PaperComponent: TagDropDownHelperText, onBlur: function () { return setTagSearch(''); }, value: selectedTag, renderInput: function (params) { return _jsx(TextField, __assign({}, params, { label: "Tag" })); } })] })] }), _jsx(Divider, { sx: { marginTop: '8px', borderBottomWidth: '2px' }, variant: 'middle' })] })), _jsxs(StyledEscalationReasonArea, { children: [_jsx(ReasonTextField, { value: reason, id: "reason-field", label: "Reason for the escalation", onChange: function (value) { return reasonOnChange(value); } }), _jsxs(Box, { color: "text.secondary", alignContent: 'right', children: [_jsx(Button, { size: "small", variant: "text", onClick: cancelEsc, sx: {
                                    width: '107px',
                                    background: 'none',
                                    color: '#387A26',
                                    ':hover': {
                                        backgroundColor: '#fafafa'
                                    }
                                }, children: "Cancel" }), _jsx(Button, { onClick: doSubmit, disabled: disableEscalationSubmit(), size: "small", sx: {
                                    ':disabled': {
                                        backgroundColor: '#bbb'
                                    }
                                }, children: "Escalate" })] })] })] }));
}
