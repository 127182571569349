var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuthentication } from '../../../../../../../common/hooks/authentication';
import CustomCollapse from '../../../../../../../components/atoms/CustomCollapse';
export default function ContractMenu(_a) {
    var _this = this;
    var actions = _a.actions, currentTenant = _a.tenant, error = _a.error, onCloseError = _a.onCloseError;
    var hasAny = useAuthentication().hasAny;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var activeActions = actions
        .filter(function (a) {
        return a.triggers.contractStatuses.includes(currentTenant.contractStatus);
    })
        .filter(function (a) { return !a.triggers.permissions || hasAny(a.triggers.permissions); })
        .filter(function (a) { return !a.triggers.custom || a.triggers.custom(currentTenant); });
    if (!activeActions || activeActions.length === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Box, { children: [_jsx(Button, { onClick: handleClick, endIcon: _jsx(KeyboardArrowDownIcon, {}), children: "Change tenant" }), _jsxs(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, children: [_jsx(CustomCollapse, { errorMessage: error, onClose: onCloseError }), activeActions.map(function (action) { return (_jsxs(MenuItem, { onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, action.execute(currentTenant)];
                                    case 1:
                                        _a.sent();
                                        handleClose();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, children: [_jsx(ListItemIcon, { children: action.icon }), _jsx(ListItemText, { children: action.title })] }, action.title)); })] })] }));
}
