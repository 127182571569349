var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Box, Button, IconButton, InputBase, List, Stack } from '@mui/material';
import { useCreateServiceMutation, useDeleteServiceMutation, useGetServicesQuery } from '../../../api/tenantApi';
import CustomCollapse from '../../../components/atoms/CustomCollapse';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { ServiceItem } from './atoms/ServiceItem';
import { useErrors } from '../../../common/hooks/errors';
export function ServicesManagerView() {
    var _this = this;
    var navigate = useNavigate();
    var addAlert = useErrors().addAlert;
    var createService = useCreateServiceMutation()[0];
    var deleteService = useDeleteServiceMutation()[0];
    var _a = useGetServicesQuery(), data = _a.data, isError = _a.isError;
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState([]), services = _c[0], setServices = _c[1];
    var _d = React.useState(''), name = _d[0], setName = _d[1];
    useEffect(function () {
        if (isError) {
            addAlert('Failed to load services');
        }
        else if (data) {
            setServices(data);
        }
    }, [addAlert, data, isError]);
    var handleClose = function () {
        navigate("/");
    };
    var addService = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createService({ name: name }).unwrap()];
                case 1:
                    _a.sent();
                    setName('');
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Failed to create service', e_1);
                    setErrorMessage('Failed to create service');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onDelete = function (service) { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteService(service.name).unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.error('Failed to delete service', e_2);
                    setErrorMessage('Failed to delete service');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Box, { padding: 2, children: _jsxs(Stack, { spacing: 2, children: [_jsx(CustomCollapse, { errorMessage: errorMessage, onClose: function () { return setErrorMessage(''); } }), _jsx(Typography, { variant: "h4", children: "Manage services" }), _jsx(List, { children: services.map(function (s) { return (_jsx(ServiceItem, { service: s, onDelete: onDelete }, s.name)); }) }), _jsxs(Box, { display: "flex", justifyContent: "space-between", children: [_jsx(InputBase, { sx: { ml: 1, flex: 1 }, value: name, onChange: function (e) { return setName(e.target.value); }, placeholder: "Add new service type" }), _jsx(IconButton, { type: "button", sx: { p: '10px' }, onClick: addService, children: _jsx(AddIcon, {}) })] }), _jsx(Box, { display: "flex", justifyContent: "end", children: _jsx(Button, { onClick: handleClose, children: "Done" }) })] }) }));
}
